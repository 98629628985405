import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import Modal from 'react-modal';
import { FileBinaryIcon } from '@primer/octicons-react';

import LayoutPortal from '../../components/layout-portal';
import Seo from '../../components/seo';
import CustomFetch from '../../components/fetch';

export default function ArchivesPage() {
    const [toDos, setToDos] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [videoSource, setVideoSource] = useState('');

    const fetchToDo = (id) => {
        if (id > 0) {
            CustomFetch(
                `${process.env.GATSBY_API_URL}/documents/archiveList`,
                'POST',
                {
                    company_contact_id: id,
                },
                function (result) {
                    setToDos(result);
                },
                function (error) {
                    toast.error(String(error));
                }
            );
        }
    };

    const handleDownload = (category, type, id) => {
        switch (category) {
            case 'generalDocument':
                CustomFetch(
                    `${process.env.GATSBY_API_URL}/documents/download/${id}`,
                    'POST',
                    {
                        where: [
                            {
                                id: 'archive',
                                value: 1,
                            },
                        ],
                    },
                    function (result) {
                        if (result.extension && result.extension === 'mp4') {
                            setModalShow(true);
                            setVideoSource(result.url);
                        } else {
                            window.open(result.url);
                        }
                    },
                    function (error) {
                        toast.error(String(error));
                    }
                );
                break;
        }
    };

    useEffect(() => {
        let userProfile = localStorage.getItem('ofpUserProfile');

        if (userProfile && userProfile !== '') {
            userProfile = JSON.parse(userProfile);
            fetchToDo(userProfile.company_contact_id);
        }
    }, []);

    return (
        <LayoutPortal>
            <Seo title="Archives" description="Client Portal - Archives" />
            <div className="container container--portal">
                <div className="grid">
                    <div className="col-lg-12">
                        <div className="pitem">
                            <div className="plist">
                                <div className="plist__header">Archives</div>
                                <div className="plist__content">
                                    <ul>
                                        {toDos.length > 0 ? (
                                            toDos.map((a) => (
                                                <li key={`to-do-${a.id}`}>
                                                    <button
                                                        onClick={() => {
                                                            handleDownload(
                                                                'generalDocument',
                                                                '',
                                                                a.id
                                                            );
                                                        }}
                                                    >
                                                        <FileBinaryIcon
                                                            size={24}
                                                        />
                                                        {a.label + ' '}
                                                        <small className="date">
                                                            [
                                                            {moment(
                                                                a.created_at
                                                            ).format(
                                                                'DD-MM-YYYY'
                                                            )}
                                                            ]
                                                        </small>{' '}
                                                        <span>View</span>
                                                    </button>
                                                </li>
                                            ))
                                        ) : (
                                            <li>
                                                <button>
                                                    No archive items has been
                                                    added for your review.
                                                </button>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={modalShow}
                    onRequestClose={() => setModalShow(false)}
                    overlayClassName={{
                        base: 'overlay-base',
                        afterOpen: 'overlay-after',
                        beforeClose: 'overlay-before',
                    }}
                    className={{
                        base: 'content-base',
                        afterOpen: 'content-after',
                        beforeClose: 'content-before',
                    }}
                    closeTimeoutMS={500}
                >
                    <>
                        <button
                            onClick={() => setModalShow(false)}
                            className="modalclose"
                        >
                            Close
                        </button>
                        <video width="640" height="480" controls>
                            <source src={videoSource} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </>
                </Modal>
            </div>
        </LayoutPortal>
    );
}
